var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DataTable',{attrs:{"name":"buyerAnalytics","items":_vm.contacts,"headers":_vm.headers,"loading":_vm.isLoading,"items-length":_vm.totalItems,"clickable":true,"initial-options":_vm.initialTableOptions},on:{"optionsChanged":_vm.onOptionsChange,"selectAll":_vm.handleSelectAll},scopedSlots:_vm._u([{key:"export",fn:function(){return [_c('v-btn',{attrs:{"dark":"","color":"primary"},on:{"click":_vm.openExportDialog}},[_vm._v(_vm._s(_vm.$tc("export"))+" ")])]},proxy:true},(_vm.selectedContactsLocal.length > 0)?{key:"body.prepend",fn:function(ref){
var isMobile = ref.isMobile;
return [_c('BuyerAnalyticsDataTableToolbar',{attrs:{"is-mobile":isMobile,"selected":_vm.selectedContactsLocal.length,"col-num":_vm.headers.length},on:{"resetSelection":_vm.resetSelection,"deleteAll":_vm.deleteAll}})]}}:null,{key:"item.select",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"row-select-checkbox",attrs:{"input-value":_vm.isRowSelected(item.id)},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.rowSelection(item)}}})]}},{key:"item.lastName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.contactDetailLink(item)}},[_vm._v(" "+_vm._s(item.firstName)+" ")]),(item.isDisabled)?_c('v-chip',{staticClass:"text-uppercase ml-2",attrs:{"small":"","text-color":"white","color":"black"},domProps:{"textContent":_vm._s(_vm.$tc('deactivated'))}}):_vm._e()]}},{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.contactDetailLink(item)}},[_vm._v(" "+_vm._s(item.lastName)+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [(item.email)?_c('Link',{attrs:{"href":item.email,"prefix":_vm.LinkType.MAIL}}):_vm._e()]}},{key:"item.company",fn:function(ref){
var item = ref.item;
return [(item.company.name)?_c('router-link',{attrs:{"to":_vm.companyDetailLink(item.company)}},[_vm._v(" "+_vm._s(item.company.name)+" ggg ")]):_c('p')]}},{key:"item.phoneMobile",fn:function(ref){
var item = ref.item;
return [(item.phoneMobile)?_c('Link',{attrs:{"href":item.phoneMobile,"prefix":_vm.LinkType.TELEPHONE}}):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('ActionButtonWithTooltip',{attrs:{"icon":"mdi-delete","tooltip":_vm.$tc('delete', 1)},on:{"click":function($event){return _vm.deleteContact(item.id)}}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }
import Axios from "axios";
import Contact from "@/entity/Contact";
import { ListResponseEntity } from "@/services/types";
import { ProjectValuationBuyerChart } from "@/entity/project-valuation/ProjectValuationBuyers";
import { TableQuery } from "@/components/data-table/types";
import filterQueryParams from "@/services/filterQueryParams";

export default class BuyerAnalyticsService {
  public static async search(
    query: TableQuery
  ): Promise<ListResponseEntity<Contact>> {
    const response = await Axios.get("/api/v2/buyer-analytics", {
      params: filterQueryParams(query),
    });
    return response.data;
  }

  public static async stats(
    query: TableQuery
  ): Promise<ProjectValuationBuyerChart[]> {
    const response = await Axios.get("/api/v2/buyer-analytics/stats", {
      params: filterQueryParams(query),
    });
    return response.data;
  }
}
